// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Center the table */
.control-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 20px;
}

/* Style the table */
.image-table {
  border-collapse: collapse; /* Merge table borders */
  width: 80%;
  margin: 20px auto;
}

/* Add border to the table */
.image-table th,
.image-table td {
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: center;
}

/* Style the table header */
.image-table th {
  background-color: #f2f2f2;
}

/* Optional: Add hover effect for table rows */
.image-table tr:hover {
  background-color: #f1f1f1;
}`, "",{"version":3,"sources":["webpack://./src/components/ControlPage/ControlPage.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA,oBAAoB;AACpB;EACE,yBAAyB,EAAE,wBAAwB;EACnD,UAAU;EACV,iBAAiB;AACnB;;AAEA,4BAA4B;AAC5B;;EAEE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;AACpB;;AAEA,2BAA2B;AAC3B;EACE,yBAAyB;AAC3B;;AAEA,8CAA8C;AAC9C;EACE,yBAAyB;AAC3B","sourcesContent":["/* Center the table */\r\n.control-page {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  text-align: center;\r\n  margin-top: 20px;\r\n}\r\n\r\n/* Style the table */\r\n.image-table {\r\n  border-collapse: collapse; /* Merge table borders */\r\n  width: 80%;\r\n  margin: 20px auto;\r\n}\r\n\r\n/* Add border to the table */\r\n.image-table th,\r\n.image-table td {\r\n  border: 1px solid #dddddd;\r\n  padding: 8px;\r\n  text-align: center;\r\n}\r\n\r\n/* Style the table header */\r\n.image-table th {\r\n  background-color: #f2f2f2;\r\n}\r\n\r\n/* Optional: Add hover effect for table rows */\r\n.image-table tr:hover {\r\n  background-color: #f1f1f1;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
