import React, { useEffect, useState } from 'react';
import './ControlPage.css';

const ControlPage = () => {
  const [images, setImages] = useState([]);

  // Function to fetch all image info
  const fetchImages = async () => {
    try {
      const response = await fetch('https://pie.hslnet.my/api/images');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setImages(data);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  // Function to reset the view counters for all images
  const resetAllCounters = async () => {
    try {
      const response = await fetch('https://pie.hslnet.my/api/reset', {
        method: 'POST',
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // Refetch to update view counts
      fetchImages();
    } catch (error) {
      console.error('Error resetting counters:', error);
    }
  };

  // Fetch images on component mount
  useEffect(() => {
    fetchImages();
  }, []);

  return (
    <div className="control-page">
      <h1>Control Page</h1>
      <button onClick={resetAllCounters}>Reset All Counters</button>

      {images.length > 0 ? (
        <table className="image-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Image</th>
              <th>Number of People</th>
            </tr>
          </thead>
          <tbody>
            {images.map((image) => (
              <tr key={image.id}>
                <td>{image.name}</td>
                <td>
                  <img src={image.imageUrl} alt={image.name} width="100" height="130" />
                </td>
                <td>{image.viewCount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>Loading images...</p>
      )}

    </div>
  );
};

export default ControlPage;