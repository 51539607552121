import React, { useState, useEffect } from 'react';
import './Main.css';

// Function to generate a random string of length 5
const generateRandomString = (length = 5) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

const ImageDisplay = ({ imageUrl, randomText }) => {
  return (
    <div>
      {/* Display the random text at the top */}
      <p className="random-text">{randomText}</p>
      {imageUrl ? (
        <img src={imageUrl} alt="Random" width="400" height="550" />
      ) : (
        <p>No more available images</p>
      )}
    </div>
  );
};

const Main = () => {
  const [currentImage, setCurrentImage] = useState(null);
  const [randomText, setRandomText] = useState('');

  // Function to fetch a random image from the backend
  const fetchRandomImage = async () => {
    try {
      const response = await fetch('https://pie.hslnet.my/api/random-image');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();

      // Set the current image
      setCurrentImage(data.imageUrl);

      // Store the image URL and timestamp in local storage
      localStorage.setItem('currentImage', data.imageUrl);
      localStorage.setItem('imageFetchTime', Date.now());
    } catch (error) {
      console.error('Error fetching image:', error);
    }
  };

  // Generate and store one random string in local storage
  useEffect(() => {
    const storedText = localStorage.getItem('randomText');
    
    if (storedText) {
      // If a random text already exists in localStorage, use it
      setRandomText(storedText);
    } else {
      // Generate new random text if none is stored
      const randomString = generateRandomString();
      setRandomText(randomString);
      localStorage.setItem('randomText', randomString);
    }
  }, []);

  // On component mount, check local storage for existing image
  useEffect(() => {
    const storedImage = localStorage.getItem('currentImage');
    const fetchTime = localStorage.getItem('imageFetchTime');
    
    // Check if storedImage exists
    if (storedImage) {
      const timeElapsed = Date.now() - fetchTime;

      // Check if the session is still valid (1 minute = 60000 milliseconds)
      if (timeElapsed < 60000) {
        setCurrentImage(storedImage); // Use the stored image
        return; // Exit useEffect to prevent fetching a new image
      }
    }

    // Fetch a new image if no valid session is found
    console.log('Fetching random image');
    fetchRandomImage();
  }, []); // Run only once on mount  

  return (
    <div className="App">
      <header className="App-header">
        <ImageDisplay imageUrl={currentImage} randomText={randomText} />
      </header>
    </div>
  );
};

export default Main;